<template>
    <svg :width="width" :height="width" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1108_13221)">
            <path
                d="M8 5.5V8L11.1247 11.1253M1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8Z"
                :stroke="color" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1108_13221">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'Time',
    props: {
        color: {
            type: String,
            default: 'white',
            required: false,
        },
        width: {
            type: Number,
            default: 50,
            required: false,
        }
    },
}
</script>

<style>

</style>