<template>
  <div>
    <div class="mt-4 mx-auto dataset-page-title" style="width: 1200px">
      <span class="blod">{{ $translate("DatasetApp.Account.AddAccount.Title") }}</span>
    </div>
    <p class="mt-2 mx-auto dataset-page-sub-title" style="width: 1200px">
      {{ $translate("DatasetApp.Account.AddAccount.SubTitle1") }}
      <br>
      {{ $translate("DatasetApp.Account.AddAccount.SubTitle2") }}
    </p>
    <v-card class="mt-4 mx-auto pa-4" width="1200">
      <v-form ref="form" v-model="valid">
        <div class="mb-1 form-title">
          {{ $translate("DatasetApp.Account.AddAccount.Message1") }}
        </div>
        <v-row class="mt-4" no-gutters>
          <v-col cols="9">
            <v-row>
              <v-col class="py-0" cols="4">
                <v-text-field v-model="account.Name" :label="$translate('DatasetApp.Account.AddAccount.AccountName')"
                  :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]" outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="8">
                <v-text-field v-model="account.Address" :label="$translate('DatasetApp.Account.AddAccount.Address')"
                  :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]" outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="4">
                <v-text-field v-model="account.PostalCode"
                  :label="$translate('DatasetApp.Account.AddAccount.PostalCode')" :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]"
                  outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="4">
                <v-text-field v-model="account.City" :label="$translate('DatasetApp.Account.AddAccount.City')"
                  :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]" outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="4">
                <v-text-field v-model="account.Country" :label="$translate('DatasetApp.Account.AddAccount.Country')"
                  :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]" outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-autocomplete v-model="selectedContact" :loading="clpLoading" :items="contacts"
                  :search-input.sync="search" no-filter :label="
                    $translate(
                      'DatasetApp.Account.AddAccount.ClientLeadPartner'
                    )
                  " outlined hide-no-data color="primary" return-object :rules="[rules.required]" dense
                  :disabled="selectedAccount != null">
                  <template slot="selection" slot-scope="data">
                    {{ data.item.contactFullName }} -
                    {{ data.item.contactEmailOffice }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.contactFullName }} -
                    {{ data.item.contactEmailOffice }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="mb-1 mt-3 form-title">
          {{ $translate("DatasetApp.Account.AddAccount.Message2") }}
        </div>
        <v-row class="mt-4" no-gutters>
          <v-col cols="9">
            <v-row>
              <v-col class="py-0" cols="3">
                <v-text-field v-model="account.ClientFirstName" :label="
                  $translate('DatasetApp.Account.AddAccount.ClientFirstName')
                " :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]" outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="3">
                <v-text-field v-model="account.ClientLastName"
                  :label="$translate('DatasetApp.Account.AddAccount.ClientLastName')" :rules="[rules.required, rules.alphanumericMinimumSpecialCharacters]"
                  outlined dense :disabled="selectedAccount != null">
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-text-field v-model="account.ClientEmail"
                  :label="$translate('DatasetApp.Account.AddAccount.ClientEmail')" :rules="[rules.required, rules.email, rules.alphanumericMinimumSpecialCharacters]"
                  outlined suffix="@kpmg.onmicrosoft.com" dense :disabled="selectedAccount != null"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="3">
                <v-text-field v-model="account.ClientPhone"
                  :label="$translate('DatasetApp.Account.AddAccount.ClientPhone')" :rules="[rules.required, rules.phone, rules.alphanumericMinimumSpecialCharacters]"
                  outlined dense :disabled="selectedAccount != null"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="3">
                <v-select v-model="account.ClientRole" :items="roleList"
                  :label="$translate('DatasetApp.Account.AddAccount.ClientRole')" :rules="[rules.required]"
                  outlined dense :disabled="selectedAccount != null"></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters>
          <v-spacer></v-spacer>
          <v-btn dark class="text-none gradien-btn" @click="addAccount" :loading="isLoading"
            :disabled="!valid || isLoading || selectedAccount != null">
            {{ $translate("DatasetApp.Account.AddAccount.Button") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import ContactsService from "@services/contacts-service.js";
import AccountsService from "@services/account-service.js";
import { localizationMixin } from "../../mixins/localization";
import { validationRulesMixin } from "../../mixins/validationRules";

export default {
  mixins: [localizationMixin, validationRulesMixin],
  props: {
    dialog: {
      type: Boolean,
    },
    selectedAccount: {
      type: Object,
    },
    sessionId: {
      type: String,
    },
  },
  data() {
    return {
      contactsService: null,
      accountService: null,
      contacts: [],
      selectedContact: null,
      isLoading: false,
      clpLoading: false,
      search: null,
      account: {},
      valid: true,
      roles: [],
    };
  },
  computed: {
    roleList() {
      return [
        "Dirigeant",
        "Direction & Administration Générale",
        "Paie & RH",
        "Marketing & Commercial",
        "Systèmes Informations",
        "Production & Technique",
        "Gérant",
        "Autre",
      ];
    }
  },
  watch: {
    search(val) {
      if (val && val.length >= 3) this.querySelections(val);
    },
  },
  created() {
    this.contactsService = new ContactsService();
    this.accountService = new AccountsService();
    if (this.selectedAccount != null) {
      this.account = this.selectedAccount;
      this.contactsService
        .GetContactsByAccount(this.account.Id)
        .then((response) => {
          let clp = response.data.values.filter(
            (role) => role.Type == "Collaborateur"
          )[0];
          let client = response.data.values.filter(
            (role) => role.Type == "Client"
          )[0];

          client.Email = client.Email.split("@")[0]

          this.selectedContact = {
            contactFullName: clp.FirstName + " " + clp.LastName,
            contactEmailOffice: clp.Email,
          };
          this.selectedAccount.Roles.forEach((element) => {
            if (element.ContactId == client.Id) {
              this.account["ClientRole"] = element.RoleName
            }
          })

          this.contacts.push(this.selectedContact);
          this.account["ClientFirstName"] = client.FirstName;
          this.account["ClientLastName"] = client.LastName;
          this.account["ClientEmail"] = client.Email;
          this.account["ClientPhone"] = client.Phone;
        });
    }
  },
  methods: {
    querySelections(v) {
      this.clpLoading = true;

      this.contactsService.SearchColloabFromReferentiel(v).then((response) => {
        this.contacts = JSON.parse(response.data);
        this.clpLoading = false;
      });
    },
    async addAccount() {
      this.isLoading = true;
      let accountToAdd = JSON.parse(JSON.stringify(this.account))
      accountToAdd.sessionId = this.sessionId;
      accountToAdd.clp = this.selectedContact.contactEmailOffice;
      accountToAdd.ClientEmail = accountToAdd.ClientEmail + '@kpmg.onmicrosoft.com'
      await this.accountService.CreateAccount(accountToAdd);
      this.isLoading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang="scss">
