<template>
  <v-app class="dataset-app-class">
    <v-container fluid>
      <AccountDetails v-if="openAccountDetails" :account="selectedAccount" :session="selectedSession"
        :tabIndex="tabIndex" @closeDetails="
  openAccountDetails = false;
getAccounts();
        "></AccountDetails>
      <div v-else>
        <v-row class="my-1" align="center" no-gutters>
          <div class="dataset-page-title">
            <span class="blod">{{ $translate("DatasetApp.Title") }}</span>
          </div>
          <a @click="getSessions()" class="ml-6 dataset-hyperlink">
            <TimeIcon :width="14" color="#747A85"/>
            {{
              $translate("DatasetApp.Refresh")
            }}
          </a>
        </v-row>
        <v-row class="my-1" align="end" no-gutters>
          <div class="dataset-page-description">
            <span class="blod">{{ $translate("DatasetApp.Description1") }}</span>
          </div>
        </v-row>
        <v-row class="my-1" align="end" no-gutters>
          <div class="dataset-page-description">
            <span class="blod">{{ $translate("DatasetApp.Description2") }}</span>
          </div>
        </v-row>
        <v-row>
          <v-col md="12" lg="4">
            <v-card class="mx-auto v-card-vh-height">
              <v-card-title class="mb-2 pr-2">
                <ListIcon class="mr-2" :width="24" color="#1C00C0"/>
                <span class="primary--text">{{
                $translate("DatasetApp.Session.Title")
                }}</span>
                <v-spacer></v-spacer>
                <v-btn v-if="itemsSessions.length != 0" dark class="text-none gradien-btn"
                  @click="dialogSession = true">
                  <AddCercleIcon class="mr-2" color="white" :width="24" />
                  {{ $translate("DatasetApp.Session.CreateSession") }}
                </v-btn>
              </v-card-title>
              <v-progress-linear v-if="loadingSessions" indeterminate color="primary"></v-progress-linear>
              <v-row v-if="itemsSessions.length == 0" class="fill-height" align="center" justify="center">
                <v-btn v-if="!loadingSessions" height="60" class="text-none gradien-btn" dark color="primary"
                  @click="dialogSession = true">
                  <v-layout class="align-center" column>
                    <AddCercleIcon class="mr-2" color="white" :width="24" />
                    {{ $translate("DatasetApp.Session.CreateSession") }}
                  </v-layout>
                </v-btn>
              </v-row>
              <v-list class="scroll-list" v-else nav>
                <v-list-item-group mandatory v-model="selectedSessionIndex" color="primary">
                  <v-list-item v-for="(item, i) in itemsSessions" :key="i">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold">{{ item.Title }} |
                        </span>
                        {{ item.Description }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col md="12" lg="8">
            <v-card class="mx-auto v-card-vh-height">
              <v-card-title class="mb-2 pr-2">
                <SocialIcon class="mr-2" :width="24" color="#1C00C0"/>
                <span class="primary--text">{{
                $translate("DatasetApp.Account.Accounts")
                }}</span>
                <v-spacer></v-spacer>
                <v-col class="py-0" cols="3">
                  <v-select v-model="selectedActivationStatus" :items="activationstatusList" item-text="value"
                    item-value="key" required outlined hide-details dense>
                  </v-select>
                </v-col>
                <v-btn v-if="itemsAccounts.length > 0" class="text-none" outlined dark color="primary"
                  @click="addAccount()">
                  <AddCercleIcon class="mr-2" color="#1C00C0" :width="24" />
                  {{ $translate("DatasetApp.Account.CreateAccount") }}
                </v-btn>
              </v-card-title>
              <v-progress-linear v-if="loadingAccounts" indeterminate color="primary"></v-progress-linear>
              <v-row v-if="itemsAccounts.length == 0" class="fill-height" align="center" justify="center">
                <v-btn v-if="!loadingAccounts" height="60" class="text-none" text dark color="primary"
                  @click="addAccount()">
                  <v-layout class="align-center" column>
                    <AddCercleIcon class="mr-2" color="#1C00C0" :width="24" />
                    {{ $translate("DatasetApp.Account.CreateAccount") }}
                  </v-layout>
                </v-btn>
              </v-row>
              <v-list class="scroll-list" v-else nav>
                <v-list-item two-line v-for="(item, i) in itemsAccounts" :key="i"
                  :style="item.IsActive == 0 ? 'background-color: #efefef' : 'background-color: white'">
                  <v-list-item-content>
                    <v-list-item-title :style="item.IsActive == 0 ? 'color: #989898' : 'color: black'">
                      <span class="font-weight-bold"> {{ item.Name }} | </span>{{
                      $translate("DatasetApp.Account.IbsCode")
                      }} :
                      {{ item.Ibs }}
                      <span class="font-weight-bold"> | </span>
                      {{ $translate("DatasetApp.Account.CLP") }} :
                      {{ item.CLP }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row class="mt-2 flex-gap" no-gutters>
                        <v-chip small :text-color="
                          item.IsActive == 0 ? '#989898' :
                            item.SynchronizedPulse == 0 ||
                              item.SynchronizedPulse == 3
                              ? 'white'
                              : 'black'
                        " :color="
                          item.IsActive == 0 ? '#e5e5e5' :
                            item.SynchronizedPulse == 0
                              ? 'grey'
                              : item.SynchronizedPulse == 1
                                ? 'pending'
                                : item.SynchronizedPulse == 2
                                  ? 'success'
                                  : 'error'
                        ">
                          {{
                          item.IsActive == 0 ? $translate("DatasetApp.AccountDeactivated") :
                          item.SynchronizedPulse == 0
                          ? $translate("DatasetApp.AccountSyncNotInitialized")
                          : item.SynchronizedPulse == 1
                          ? $translate("DatasetApp.AccountSyncInProgress")
                          : item.SynchronizedPulse == 2
                          ? $translate("DatasetApp.AccountSyncProcessDone")
                          : $translate("DatasetApp.AccountSyncError")



                          }}
                        </v-chip>
                        <v-chip small :text-color="
                          item.IsActive == 0 ? '#989898' :
                            item.SynchronizedBank == 0 ||
                              item.SynchronizedBank == 3
                              ? 'white'
                              : 'black'
                        " :color="
                          item.IsActive == 0 ? '#e5e5e5' :
                            item.SynchronizedBank == 0
                              ? 'grey'
                              : item.SynchronizedBank == 1
                                ? 'pending'
                                : item.SynchronizedBank == 2
                                  ? 'success'
                                  : 'error'
                        ">
                          {{
                          item.IsActive == 0 ? $translate("DatasetApp.BankDeactivated") :
                          item.SynchronizedBank == 0
                          ? $translate("DatasetApp.BankNotInitialized")
                          : item.SynchronizedBank == 1
                          ? $translate("DatasetApp.BankInProgress")
                          : item.SynchronizedBank == 2
                          ? $translate("DatasetApp.BankProcessDone")
                          : $translate("DatasetApp.BankError")



                          }}
                        </v-chip>
                        <v-chip small :text-color="
                          item.IsActive == 0 ? '#989898' :
                            item.SynchronizedIndicators == 0 ||
                              item.SynchronizedIndicators == 3
                              ? 'white'
                              : 'black'
                        " :color="
                          item.IsActive == 0 ? '#e5e5e5' :
                            item.SynchronizedIndicators == 0
                              ? 'grey'
                              : item.SynchronizedIndicators == 1
                                ? 'pending'
                                : item.SynchronizedIndicators == 2
                                  ? 'success'
                                  : 'error'
                        ">
                          {{
                          item.IsActive == 0 ? $translate("DatasetApp.IndicatorsDeactivated") :
                          item.SynchronizedIndicators == 0
                          ? $translate(
                          "DatasetApp.IndicatorsNotInitialized"
                          )
                          : item.SynchronizedIndicators == 1
                          ? $translate("DatasetApp.IndicatorsInProgress")
                          : item.SynchronizedIndicators == 2
                          ? $translate("DatasetApp.IndicatorsProcessDone")
                          : $translate("DatasetApp.IndicatorsError")



                          }}
                        </v-chip>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row no-gutters>
                      <v-menu bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-if="item.IsActive == 0 || item.ActivationStatus == 1"
                            :disabled="item.IsActive == 0 || item.ActivationStatus == 1" class="mr-2" icon
                            v-bind="attrs" v-on="on">
                            <PenIcon :width="24" color="rgba(0,0,0,0.26)"/>
                          </v-btn>
                          <v-btn v-else class="mr-2" icon v-bind="attrs" v-on="on">
                            <PenIcon :width="24" color="#1C00C0"/>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list nav>
                            <v-list-item-group color="primary">
                              <v-list-item v-for="(n, j) in menu" :key="j" :disabled="n.disabled(item)"
                                @click="n.open(i)">
                                <v-list-item-title>{{
                                n.name
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                      <v-tooltip v-if="item.IsActive == 0" top color="white">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="d-inline-block">
                            <v-btn v-if="item.IsActive == 0"
                              :disabled="item.ActivationStatus == 1 || item.SynchronizedPulse == 1"
                              :loading="item.ActivationStatus == 1"
                              :color="item.ActivationStatus == 3 ? '#bc204b' : '#1C00C0'" icon
                              @click="activateDesactivateAccount(item)">
                              <UnarchiveIcon v-if="item.ActivationStatus == 1 || item.SynchronizedPulse == 1"
                                color="rgba(0,0,0,0.26)" :width="24" />
                              <UnarchiveIcon v-else-if="item.ActivationStatus == 3" color="#bc204b" :width="24" />
                              <UnarchiveIcon v-else color="#1C00C0" :width="24" />
                            </v-btn>
                          </div>
                        </template>
                        <div style="color: black">
                          <span>{{ item.ActivationStatus == 1 ? $translate("DatasetApp.Account.ProcessInProgress") :
                          $translate("DatasetApp.Account.ClickToActive")
                          
                          
                          
                          }}</span>
                        </div>
                      </v-tooltip>
                      <v-tooltip v-if="item.IsActive == 1" top color="white">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="d-inline-block">
                            <v-btn v-if="item.IsActive == 1"
                              :disabled="item.ActivationStatus == 1 || item.SynchronizedPulse == 1"
                              :loading="item.ActivationStatus == 1"
                              :color="item.ActivationStatus == 3 ? '#bc204b' : '#1C00C0'" icon
                              @click="activateDesactivateAccount(item)">
                              <ArchiveIcon v-if="item.ActivationStatus == 1 || item.SynchronizedPulse == 1"
                                color="rgba(0,0,0,0.26)" :width="24" />
                              <ArchiveIcon v-else-if="item.ActivationStatus == 3" color="#bc204b" :width="24" />
                              <ArchiveIcon v-else color="#1C00C0" :width="24" />
                            </v-btn>
                          </div>
                        </template>
                        <div style="color: black">
                          <span>{{ item.ActivationStatus == 1 ? $translate("DatasetApp.Account.ProcessInProgress") :
                          $translate("DatasetApp.Account.ClickToUnactive")
                          
                          
                          
                          }}</span>
                        </div>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="dialogSession" max-width="380px">
      <AddSessionDialog v-if="dialogSession" :dialog="dialogSession" @closeDialog="
  dialogSession = false;
getSessions();
selectedSessionIndex = 0;
      " />
    </v-dialog>
  </v-app>
</template>
<script>
import AddSessionDialog from "./AddSessionDialog.vue";
import AccountDetails from "./AccountDetails.vue";
import SessionsService from "@services/sessions-service.js";
import AccountsService from "@services/account-service.js";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import { utilsMixin } from "@mixins/utils";

//icons
import AddCercleIcon from "@assets/AddCercle.vue";
import ArchiveIcon from "@assets/Archive.vue";
import UnarchiveIcon from "@assets/Unarchive.vue";
import ListIcon from "@assets/List.vue";
import PenIcon from "@assets/Pen.vue";
import SocialIcon from "@assets/Social.vue";
import TimeIcon from "@assets/Time.vue";

export default {
  name: "DatasetSessions",
  mixins: [baseProps, localizationMixin, utilsMixin],
  components: {
    AddSessionDialog,
    AccountDetails,
    //icons
    AddCercleIcon,
    ArchiveIcon,
    UnarchiveIcon,
    ListIcon,
    PenIcon,
    SocialIcon,
    TimeIcon
  },
  data() {
    return {
      sessionsService: null,
      accountsService: null,
      dialogSession: false,
      dialogAccount: false,
      dialogBankData: false,
      dialogMyIndicators: false,
      sortBySessions: "CreatedAt",
      sortDescSessions: true,
      totalSessions: 0,
      optionsSessions: {},
      searchSessions: null,
      loadingSessions: false,
      selectedSessionIndex: 0,
      selectedAccountIndex: null,
      sortByAccounts: "IsActive desc, CreatedAt desc",
      sortDescAccounts: true,
      totalAccounts: 0,
      optionsAccounts: {},
      loadingAccounts: false,
      itemsPerPage: [15, 20, 25],
      itemsSessions: [],
      itemsAccounts: [],
      openAccountDetails: false,
      tabIndex: 0,
      selectedActivationStatus: null,
      pollInterval: null
    };
  },
  computed: {
    activationstatusList() {
      return [
        {
          key: null,
          value: this.$translate("DatasetApp.Account.AllAccounts"),
        },
        {
          key: true,
          value: this.$translate("DatasetApp.Account.Active"),
        },
        {
          key: false,
          value: this.$translate("DatasetApp.Account.Unactive"),
        }
      ]
    },
    menu() {
      return [
        {
          name: this.$translate("DatasetApp.Account.Account"),
          open: (index) => {
            this.selectedAccountIndex = index;
            this.tabIndex = 0;
            this.openAccountDetails = true;
          },
          disabled: (item) => {
            return item.SynchronizedPulse != 2 ? true : false;
          },
        },
        {
          name: this.$translate("DatasetApp.Bank.BankData"),
          open: (index) => {
            this.selectedAccountIndex = index;
            this.tabIndex = 1;
            this.openAccountDetails = true;
          },
          disabled: (item) => {
            return item.SynchronizedBank == 1 || item.SynchronizedPulse != 2
              ? true
              : false;
          },
        },
        {
          name: this.$translate("DatasetApp.MyIndicator.Indicators"),
          open: (index) => {
            this.selectedAccountIndex = index;
            this.tabIndex = 2;
            this.openAccountDetails = true;
          },
          disabled: (item) => {
            return item.SynchronizedIndicators == 1 || item.SynchronizedBank == 1 || item.SynchronizedBankTypologyId == null ||
              item.SynchronizedPulse != 2
              ? true
              : false;
          },
        },
      ];
    },
    selectedSession() {
      return this.itemsSessions[this.selectedSessionIndex];
    },
    selectedAccount() {
      return this.selectedAccountIndex != null
        ? this.itemsAccounts[this.selectedAccountIndex]
        : null;
    },
    headersSessions() {
      return [
        {
          text: this.$translate("DatasetApp.Session.SessionName"),
          value: "Title",
        },
        {
          text: this.$translate("DatasetApp.Session.CreationDate"),
          value: "CreatedAt",
        },
      ];
    },
    headersAccounts() {
      return [
        {
          text: this.$translate("DatasetApp.Account.IbsCode"),
          align: "start",
          sortable: false,
          value: "Ibs",
        },
        {
          text: this.$translate("DatasetApp.Account.AccountName"),
          value: "Name",
        },
        { text: this.$translate("DatasetApp.Account.CLP"), value: "CLP" },
        {
          text: this.$translate("DatasetApp.Account.SyncPulse"),
          value: "SynchronizedPulse",
        },
        {
          text: this.$translate("DatasetApp.Account.SyncBank"),
          value: "SynchronizedBank",
        },
        {
          text: this.$translate("DatasetApp.Account.SyncIndicators"),
          value: "SynchronizedIndicators",
        },
        {
          text: this.$translate("DatasetApp.Account.CreationDate"),
          value: "CreatedAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
  watch: {
    optionsSessions() {
      this.getSessions();
    },
    optionsAccounts() {
      this.getAccounts();
    },
    async selectedSession(value) {
      if (value != null) {
        await this.getAccounts();
        this.selectedAccountIndex = null;
      }
    },
    async selectedActivationStatus() {
      await this.getAccounts();
    }
  },
  created() {
    this.tableFooterOptions = {
      "disable-items-per-page": false,
      "items-per-page-options": this.itemsPerPage,
    };
    this.sessionsService = new SessionsService();
    this.accountsService = new AccountsService();
  },
  mounted() {
    this.getSessions();
  },
  beforeDestroy() {
    if (this.pollInterval != null) clearInterval(this.pollInterval);
  },
  methods: {
    addAccount() {
      (this.selectedAccountIndex = null), (this.tabIndex = 0);
      this.openAccountDetails = true;
    },
    getSessions() {
      /*const { sortBy, sortDesc, page, itemsPerPage } = this.optionsSessions;*/
      this.loadingSessions = true;
      this.itemsSessions = [];
      this.totalSessions = 0;
      this.sessionsService
        //.GetSessions(this.searchSessions, itemsPerPage, page, sortBy, sortDesc)
        .GetSessions(this.sortBySessions, this.sortDescSessions)
        .then((res) => {
          this.itemsSessions = res.data.values;
          this.totalSessions = res.data.count;
        })
        .catch((ex) => {
          console.log(ex);
        })
        .finally(() => {
          this.loadingSessions = false;
        });
    },
    rowClickSessions(item, row) {
      //this.sessionCollapsed = true;
      row.select(true);
      this.selectedSession = item;
    },
    getAccounts() {
      /*const { sortBy, sortDesc, page, itemsPerPage } = this.optionsAccounts;*/
      if (this.pollInterval != null) clearInterval(this.pollInterval);

      this.itemsAccounts = [];
      this.totalAccounts = 0;
      this.loadingAccounts = true;
      this.accountsService
        /*.GetAccountsBySession(
          this.selectedSession.Id,
          itemsPerPage,
          page,
          sortBy,
          sortDesc
        )*/
        .GetAccountsBySession(
          this.selectedSession.Id,
          this.sortByAccounts,
          this.sortDescAccounts,
          this.selectedActivationStatus
        )
        .then((res) => {
          this.itemsAccounts = res.data.values;
          this.totalAccounts = res.data.count;
        })
        .catch((ex) => {
          console.log(ex);
        })
        .finally(() => {
          this.loadingAccounts = false;
        });
      this.pollInterval = setInterval(() => {
        this.accountsService
          /*.GetAccountsBySession(
            this.selectedSession.Id,
            itemsPerPage,
            page,
            sortBy,
            sortDesc
          )*/
          .GetAccountsBySession(
            this.selectedSession.Id,
            this.sortByAccounts,
            this.sortDescAccounts,
            this.selectedActivationStatus
          )
          .then((res) => {
            this.itemsAccounts = res.data.values;
            this.totalAccounts = res.data.count;
          })
          .catch((ex) => {
            console.log(ex);
          })
          .finally(() => {
            this.loadingAccounts = false;
          });
      }, 10000)

    },
    async activateDesactivateAccount(item) {
      if (item.IsActive == 0) {
        await this.accountsService.ActivateDesativateAccount({
          accountNumber: item.Ibs,
          action: 1
        })
      }
      else {
        await this.accountsService.ActivateDesativateAccount({
          accountNumber: item.Ibs,
          action: 0
        })
      }
      await this.getAccounts();
    }
  },
};
</script>
<style lang="scss">
.dataset-app-class {
  background-color: #fcfcfc !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  min-height: 100vh;

  .v-chip:before {
    background-color: transparent;
  }

  .flex-gap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .v-card,
  .v-stepper {
    box-shadow: 0px 12px 28px rgba(87, 94, 107, 0.08) !important;
    border-radius: 16px !important;
    flex-flow: column;

    display: flex;

    .v-card__title {
      .v-list-item {
        padding: 0px;

        .v-list-item__icon {
          margin-right: 16px;
        }
      }
    }
  }

  .v-card-vh-height {
    height: calc(100vh - 8rem);
  }

  .scroll-list {

    flex-grow: 1;
    overflow-y: auto;

    .v-list-item {
      box-sizing: border-box;
      padding: 0px 16px;
      box-shadow: 0px 4px 8px rgba(87, 94, 107, 0.08);
    }

    .v-list-item__content {
      padding: 16px 0px;
    }

    .v-list-item--active {
      color: #1C00C0;
      border: 1px solid #1C00C0;
      background-color: #e9edfc;
      box-shadow: 0px 0px 0px 1px #1C00C0, 0px 0px 0px #ffffff,
        0px 10px 20px rgba(0, 40, 210, 0.25) !important;
    }

    .v-list-item:not(.v-list-item--active) {
      border: 0.5px solid #dee4eb;
    }

    .v-list--nav .v-list-item,
    .v-list--nav .v-list-item:before {
      border-radius: 8px;
    }
  }

  .v-list .v-list-item--active .v-icon {
    color: #1C00C0;
  }

  .v-list-item--link:before {
    background-color: white;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dee1e7;
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  .v-btn {
    border-radius: 8px;
  }

  .v-btn[disabled] {
    background: linear-gradient(78.94deg, #c3cbd7 -10.04%, #c3cbd7 118.67%);
  }

  .gradien-btn {
    background: linear-gradient(78.94deg, #aa21cf -10.04%, #1C00C0 118.67%);
  }

  .dataset-page-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    height: fit-content;
    /* or 150% */

    display: flex;
    align-items: center;
    color: #1C00C0;
  }

  .dataset-page-description {
    font-size: 14px;
    line-height: 18px;
    color: #1C00C0;

    .blod {
      font-weight: 400;
    }
  }

  .dataset-hyperlink {
    font-weight: 600 !important;
    font-size: 16px;
    text-decoration-line: underline !important;
    color: #747a85;
  }

  .dataset-page-sub-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #1C00C0;
  }

  .dataset-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #080036;
  }

  .v-btn-rec {
    width: 36px !important;
    min-width: 36px !important;
  }

  .dataset-tabs {
    .v-item-group {
      width: fit-content;
      box-shadow: 0px 12px 28px rgb(87 94 107 / 8%) !important;
      border-radius: 16px !important;
    }
  }

  .theme--light.v-tabs>.v-tabs-bar {
    border-radius: 8px;
  }

  .form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C00C0;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 8px;
  }

  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    border: 2px solid #e9edfc !important;
  }

  .theme--light.theme--light.v-label:is(.v-label--active) {
    color: #1C00C0;
  }

  .engine {
    background-image: url(https://www.svgrepo.com/show/9344/train.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>