import { localizationMixin } from "@mixins/localization";

export const validationRulesMixin = {
    mixins: [localizationMixin],
    computed: {
        rules() {
            return {
              required: v => !!v || this.$translate('DatasetApp.Common.RequiredField'),
              email: v => /^((?!@).)*$/.test(v) || this.$translate('DatasetApp.Common.InvalidField'),
              phone: v => /^[0-9]*$/.test(v) || this.$translate('DatasetApp.Common.InvalidField'),
              alphanumericMinimumSpecialCharacters: v => /^[^<>"]*$/.test(v) || this.$translate('DatasetApp.Common.InvalidField')
      
            }
        }
    },
};
