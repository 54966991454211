<template>
    <svg :width="width" :height="width" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.9916 20.9988C8.47282 20.9988 5.95403 21.0019 3.43524 20.9976C2.27865 20.9951 1.34815 20.2834 1.07733 19.1991C1.02515 18.9911 1.00279 18.7706 1.00217 18.5564C0.998441 13.8596 0.998441 9.16341 1.00341 4.46662C1.00341 4.27721 1.04192 4.08283 1.09658 3.90025C1.2233 3.47796 1.36679 3.06125 1.51027 2.64455C1.8575 1.63725 2.71842 1.00443 3.77874 1.00381C8.58401 0.998839 13.3887 0.998839 18.1939 1.00319C19.225 1.00381 20.0804 1.61676 20.4375 2.58307C20.6226 3.08361 20.8028 3.58539 20.9804 4.08842C21.0003 4.14494 20.9991 4.21076 20.9991 4.27224C20.9991 9.03796 20.9997 13.8043 20.996 18.57C20.9953 19.7195 20.2773 20.656 19.1909 20.9218C18.9536 20.9802 18.7008 20.9957 18.4548 20.9957C15.9671 21.0007 13.4793 20.9988 10.9916 20.9988ZM1.94881 4.82433V5.08516C1.94881 9.53975 1.94881 13.995 1.94881 18.4495C1.94881 19.4724 2.52462 20.0468 3.54767 20.0468C8.51381 20.0468 13.4806 20.0468 18.4467 20.0468C19.4704 20.0468 20.0474 19.473 20.0474 18.4514C20.0481 13.9968 20.0474 9.54161 20.0474 5.08702C20.0474 5.00381 20.0474 4.91997 20.0474 4.82433H1.94881ZM19.8431 3.84498C19.7586 3.5736 19.6828 3.3314 19.607 3.08858C19.3598 2.29491 18.8958 1.95273 18.066 1.95273C16.2683 1.95273 14.4701 1.95273 12.6725 1.95273C9.74308 1.95273 6.81371 1.95459 3.88433 1.95087C3.37002 1.95024 2.90663 2.09805 2.64699 2.54705C2.42089 2.93829 2.2979 3.38915 2.11652 3.84498H19.8437H19.8431Z"
            :fill="color" :stroke="color" stroke-width="0.5" />
        <path
            d="M14.2556 11.1463C14.0196 11.3488 13.7866 11.5493 13.5363 11.7648C12.8928 11.1364 12.2083 10.4675 11.4846 9.7608V15.2655H10.5386V9.82601C10.52 9.81483 10.5013 9.80427 10.4821 9.79309C9.79632 10.4638 9.11056 11.1339 8.45089 11.7785C8.21175 11.5568 7.98627 11.3488 7.76514 11.1438C7.93844 10.9637 8.09932 10.788 8.26889 10.6215C9.04845 9.85644 9.82924 9.0932 10.6119 8.33183C10.8715 8.07907 11.1231 8.07597 11.3828 8.3281C12.3157 9.2348 13.2456 10.1452 14.1761 11.055C14.1972 11.0761 14.2152 11.1003 14.255 11.1469L14.2556 11.1463Z"
            :fill="color" :stroke="color" stroke-width="0.5" stroke-linejoin="round" />
    </svg>


</template>

<script>
export default {
    name: 'AddCercle',
    props: {
        color: {
            type: String,
            default: 'white',
            required: false,
        },
        width: {
            type: Number,
            default: 50,
            required: false,
        },
    },
}
</script>

<style>

</style>