import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle accounts
 */
export default class AccountsService extends AbstractService {

    constructor() {
        super();

        this.accountsClient = super.createClient(configuration.DatasetApi.BaseUrl);
    }

    /**
     * Get accounts with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetAccounts(search,filterStatusItems, itemsPerPage, pageNumber, sortBy, sortDesc) {
        var params = {
            "$top": itemsPerPage,
            "$skip": itemsPerPage * (pageNumber - 1),
            
            "$orderBy": getOrderby(sortBy, sortDesc),
            "$count": true,
            "$expand": 'Delegations($filter=IsDeleted eq false)'
        }
        let filters = []
        if(search != null && search != '')
        filters.push(`contains(AccountLegalName,'${search}')`)
        
        if(filterStatusItems != null && filterStatusItems.length != 0) {
            let statusFilter = 'Status in (' + filterStatusItems.join(",") + ')'
            filters.push(statusFilter)
        }
        if(filters.length > 0)
            params["$filter"] = filters.join(" and ")
        return this.accountsClient.get("v2/Accounts", {
            params : params
        });
    }

    GetTypologies() {
        return this.accountsClient.get(`/v2/Account/AccountTypologies`);
    }

    SearchAccountFromReferentiel(searchTerm) {
        return this.accountsClient.get(`/v2/Account/SearchAccountFromReferentiel?SearchTerm=${searchTerm}`);
    }

    /**
     * Get accounts with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
     GetAccountsBySession(sessionId/*, itemsPerPage, pageNumber*/, sortBy, sortDesc, activationStatusFilter) {
        
        var params = {
            /*"$top": itemsPerPage ? itemsPerPage : 15,
            "$skip": itemsPerPage * (pageNumber - 1) ? itemsPerPage * (pageNumber - 1) : 0,*/
            "$orderBy": sortBy,
            "$count": true,
            "sessionId": sessionId,
            "$expand": 'Roles'
        }
        let filters = []

        if(activationStatusFilter != null) {
            let statusFilter = `IsActive eq ${activationStatusFilter}`
            filters.push(statusFilter)
        }

        if(filters.length > 0)
            params["$filter"] = filters.join(" and ")

        return this.accountsClient.get("v2/Account/GetAccountsBySession", {
            params : params
        });
    }

    /**
    * Get accounts with filters
    * @param {string} search
    */
    FilterAccounts(search) {
        let accountConfig = {
            params: {
                "$top": 100,
                "$count": true,
                "$expand": 'Team',
            }
        };

        if (search && search !== "") {
            accountConfig.params["$filter"] = `contains(IbsNumber,'${search}') 
           or contains(BusinessUnit,'${search}') 
           or contains(Name,'${search}') 
           or contains(Region,'${search}') 
           or contains(EngagementName,'${search}') 
           or contains(EngagementPartner,'${search}') 
           or contains(TeamContact,'${search}')`
        }

        return this.accountsClient.get("v2/Account", accountConfig);
    }
    
    /**
    * Get accounts by Id
    * @param {string} id
    */
     GetAccountsById(id) {
        return this.accountsClient.get(`v2/Accounts(${id})`)
    }

    /**
     * Delete account by id
     * @param {string} id
     */
    DeleteAccount(id) {
        return this.accountsClient.delete(`v2/Accounts(${id})`)
    }

    /**
     * Updates account by id
     * @param {string} id
     * @param {any} accountBody
     */
    UpdateAccount(id, accountBody) {
        return this.accountsClient.patch(`v2/Account(${id})`, accountBody)
    }

    /**
     * Create a account
     * @param {any} accountBody
     */
    CreateAccount(accountBody) {
        return this.accountsClient.post("v2/Account", accountBody);
    }

    /**
     * Add Bankin Data to an Account
     * @param {any} object
     */
     AddBankinData(object) {
        return this.accountsClient.post("v2/Account/AlimBankinData", object);
    }

    /**Activate desactivate an account
     * @param {any} object
     */
    ActivateDesativateAccount(object){
        return this.accountsClient.post("v2/Account/ActivateDesactivateAccount",object);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy) {
        orderby = sortBy;
        if (sortDesc)
            orderby = orderby + " desc"
    }

    return orderby;
}