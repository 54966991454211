import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle banking data
 */
 export default class BankingDataService extends AbstractService {

    constructor() {
        super();

        this.bankingDataClient = super.createClient(configuration.DatasetApi.BaseUrl);
    }

    /**
    * Gets all banking data
    */
     GetAllBankingData() {
        return this.bankingDataClient.get(`v2/BankingData`)
    }

    /**
    * Gets banking data by Id
    * @param {string} id
    */
     GetBankingDataById(id) {
        return this.bankingDataClient.get(`v2/BankingData(${id})`)
    }

    /**Filter banking data by ibs */
    FilterBankingDataByIbs(search){
        let bankingDataConfig = {
            params: {
                "$count": true,
            }
        };
        if(search && search != ""){
            bankingDataConfig.params["$filter"] = `AccountNumber eq '${search}'`
        }
        return this.bankingDataClient.get("v2/BankingData", bankingDataConfig)
    }

    /**Filter banking data by ibs and accountReference */
    FilterBankingDataByIbsAndAccountReference(ibs, accountReference){
        let bankingDataConfig = {
            params: {
                "$count": true,
            }
        };
        if(ibs && ibs != "" && accountReference && accountReference != ""){
            bankingDataConfig.params["$filter"] = `AccountNumber eq '${ibs}' and AccountReference eq '${accountReference}'`
        }
        return this.bankingDataClient.get("v2/BankingData", bankingDataConfig)
    }

    /**
     * Deletes banking data by id
     * @param {string} id
     */
    DeleteBankingData(id) {
        return this.bankingDataClient.delete(`v2/BankingData(${id})`)
    }

    /**
     * Updates bankingData by id
     * @param {string} id
     * @param {any} accountBody
     */
     UpdateBankingData(id, accountBody) {
        return this.bankingDataClient.patch(`v2/BankingData(${id})`, accountBody)
    }

    /**
     * Creates a banking data
     * @param {any} bankingDataBody
     */
    CreateBankingData(bankingDataBody) {
        return this.bankingDataClient.post("v2/BankingData", bankingDataBody);
    }
}
