var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4 mx-auto dataset-page-title",staticStyle:{"width":"1200px"}},[_c('span',{staticClass:"blod"},[_vm._v(_vm._s(_vm.$translate("DatasetApp.MyIndicator.AddMyIndicators.Title")))])]),_c('p',{staticClass:"mt-2 mx-auto dataset-page-sub-title",staticStyle:{"width":"1200px"}},[_vm._v(" "+_vm._s(_vm.$translate("DatasetApp.MyIndicator.AddMyIndicators.SubTitle"))+" ")]),_c('v-card',{staticClass:"mt-4 mx-auto pa-4",attrs:{"width":"1200"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"mb-1 form-title"},[_vm._v(" "+_vm._s(_vm.$translate("DatasetApp.MyIndicator.AddMyIndicators.Message"))+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.years,"label":_vm.$translate(
                      'DatasetApp.MyIndicator.AddMyIndicators.FiscalYear'
                    ),"rules":[_vm.rules.required],"outlined":"","dense":"","multiple":""},model:{value:(_vm.selectedYears),callback:function ($$v) {_vm.selectedYears=$$v},expression:"selectedYears"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.months,"label":_vm.$translate(
                      'DatasetApp.MyIndicator.AddMyIndicators.FirstMonth'
                    ),"rules":[_vm.rules.required],"outlined":"","dense":"","item-value":"index"},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"text-none",attrs:{"outlined":"","disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.generateData}},[_vm._v(_vm._s(_vm.$translate( "DatasetApp.MyIndicator.AddMyIndicators.Generate" )))])],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.items.length > 0)?_c('v-data-table',{staticClass:"my-indicators-data-table-class",staticStyle:{"width":"100vw"},attrs:{"height":"400","calculate-widths":"","headers":_vm.headers,"items":_vm.items,"single-select":true,"item-key":"periodId","sort-desc":false,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var items = ref.items;
                    var headers = ref.headers;
return [_c('tbody',_vm._l((items),function(item,idx){return _c('tr',{key:idx},_vm._l((headers),function(header,key){return _c('td',{key:key},[(key != 0)?_c('v-edit-dialog',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.save),expression:"save"}],attrs:{"return-value":item[header.value],"save-text":_vm.$translate('DatasetApp.Common.Save'),"cancel-text":_vm.$translate('DatasetApp.Common.Discard')},on:{"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","value":item[header.value],"clearable":"","label":_vm.$translate('DatasetApp.Common.Edit'),"single-line":""},on:{"input":function ($value) { return (item[header.value] = parseFloat($value) || null); }}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_c('span',[_vm._v(_vm._s(item[header.value]))])],1)}),0)}),0)]}}],null,false,3716877691)}):_vm._e()],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none gradien-btn",attrs:{"disabled":_vm.items.length == 0,"loading":_vm.isLoading,"dark":""},on:{"click":function($event){return _vm.addIndicators()}}},[_vm._v(" "+_vm._s(_vm.$translate("DatasetApp.MyIndicator.AddMyIndicators.Button"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }