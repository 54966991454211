
function getDatasetApiBaseUrl() {

    if (process.env.NODE_ENV === "development") {
        return `${window.baseDataset}`;
    }
    else {
        if (window.baseUrl.includes("api.pprod")) {
            return `${window.baseUrl}/pprod/dataset`
        }
        
        return `${window.baseUrl}/dataset`;
    }
}

export default {
    DatasetApi: {
        BaseUrl:
        {
            url: getDatasetApiBaseUrl(),
            version: null
        },
    }
}