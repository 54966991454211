<template>
    <svg :width="width" :height="width" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 9C1.5 10.9891 2.29018 12.8968 3.6967 14.3033C5.10322 15.7098 7.01088 16.5 9 16.5C10.9891 16.5 12.8968 15.7098 14.3033 14.3033C15.7098 12.8968 16.5 10.9891 16.5 9C16.5 7.01088 15.7098 5.10322 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5C7.01088 1.5 5.10322 2.29018 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9Z"
            :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.00073 12.771V5.22852M12.772 8.99975H5.22949" :stroke="color" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: 'AddCercle',
    props: {
        color: {
            type: String,
            default: 'white', // green, green--outline, white
            required: false,
        },
        width: {
            type: Number,
            default: 50,
            required: false,
        },
    },
}
</script>

<style>

</style>