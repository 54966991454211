import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle Sessions
 */
export default class SessionsService extends AbstractService {

    constructor() {
        super();
        this.SessionsClient = super.createClient(configuration.DatasetApi.BaseUrl);
    }

    /**
     * Get Sessions with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetSessions(/*search, itemsPerPage, pageNumber,*/ sortBy, sortDesc) {
        var params = {
            /*"$top": itemsPerPage,
            "$skip": itemsPerPage * (pageNumber - 1),*/
            "$orderBy": getOrderby(sortBy, sortDesc),
            "$count": true,
        }
        
        /*let filters = []
        if(search != null && search != '')
            filters.push(`contains(title,'${search}')`)
        
        if(filters.length > 0)
            params["$filter"] = filters.join(" and ")*/
        
        return this.SessionsClient.get("v2/Session", {
            params : params
        });

    }

    /**
    * Get Sessions with filters
    * @param {string} search
    */
    FilterSessions(search) {
        let SessionConfig = {
            params: {
                "$count": true
            }
        };

        if (search && search !== "") {
            SessionConfig.params["$filter"] = `RequestId eq ${search}`
        }

        return this.SessionsClient.get("v2/Session", SessionConfig);
    }

    /**
    * Get Sessions by Request
    * @param {string} requestId
    */
    GetSessionsByRequest(requestId) {
        let SessionConfig = {
            params: {
                "$count": true,
            }
        };

        if (requestId && requestId !== "") {
            SessionConfig.params["$filter"] = `RequestId eq ${requestId}`
        }

        return this.SessionsClient.get("v2/Session", SessionConfig);
    }

    /**
     * Delete Session by id
     * @param {string} id
     */
    DeleteSession(id) {
        return this.SessionsClient.delete(`v2/Session(${id})`)
    }

    /**
     * Updates Session by id
     * @param {string} id
     * @param {any} SessionBody
     */
    UpdateSession(id, SessionBody) {
        return this.SessionsClient.patch(`v2/Session(${id})`, SessionBody)
    }

    /**
     * Create a Session
     * @param {any} SessionBody
     */
    CreateSession(SessionBody) {
        return this.SessionsClient.post("v2/Session", SessionBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy) {
        orderby = sortBy;
        if (sortDesc)
            orderby = orderby + " desc"
    }

    return orderby;
}