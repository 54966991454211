
export const utilsMixin = {
  methods: {
    randomFloat() {
      const int = window.crypto.getRandomValues(new Uint32Array(1))[0]
      return int / 2**32
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    randomIntFromInterval(min, max) { 
      return Number((this.randomFloat() * (max - min + 1) + min).toFixed(2))
    }
  },
  computed: {
    months() {
      return [
        { index: 1, text: this.$translate("DatasetApp.Common.Months.January") },
        {
          index: 2,
          text: this.$translate("DatasetApp.Common.Months.Fabruary"),
        },
        { index: 3, text: this.$translate("DatasetApp.Common.Months.March") },
        { index: 4, text: this.$translate("DatasetApp.Common.Months.April") },
        { index: 5, text: this.$translate("DatasetApp.Common.Months.May") },
        { index: 6, text: this.$translate("DatasetApp.Common.Months.June") },
        { index: 7, text: this.$translate("DatasetApp.Common.Months.July") },
        { index: 8, text: this.$translate("DatasetApp.Common.Months.August") },
        {
          index: 9,
          text: this.$translate("DatasetApp.Common.Months.September"),
        },
        {
          index: 10,
          text: this.$translate("DatasetApp.Common.Months.October"),
        },
        {
          index: 11,
          text: this.$translate("DatasetApp.Common.Months.November"),
        },
        {
          index: 12,
          text: this.$translate("DatasetApp.Common.Months.December"),
        },
      ];
    },
    years() {
      let currentYear = new Date().getFullYear();
      let years = [];
      let startYear = 1980;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years.reverse();
    },
  },
};
