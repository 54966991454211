import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle Banks
 */
export default class ContactsService extends AbstractService {

    constructor() {
        super();
        this.ContactsClient = super.createClient(configuration.DatasetApi.BaseUrl);
    }

    SearchColloabFromReferentiel(searchTerm) {
        return this.ContactsClient.get(`/v2/Contact/SearchCollabFromReferentiel?SearchTerm=${searchTerm}`);
    }

    GetContactsByAccount(accountId) {
        return this.ContactsClient.get(`/v2/Contact/GetContactsByAccount?accountId=${accountId}`);
    }

    AddAccount(account) {
        return this.ContactsClient.post(`/api/accounts?api-version=1.0`, account);
    }

    AddContact(contact) {
        return this.ContactsClient.post(`/api/contacts?api-version=1.0`, contact);
    }

    AddRole(role) {
        return this.ContactsClient.post(`/api/roles?api-version=1.0`, role);
    }

    AddAccessRight(accessRight) {
        return this.ContactsClient.post(`/api/accessrights/multiple?api-version=1.0`, accessRight);
    }

    AddGigyaAccount(gigyaAccount) {
        return this.ContactsClient.post(`/api/gigya?api-version=1.0`, gigyaAccount);
    }

    AcceptTerms(userCredentials) {
        return this.ContactsClient.post(`/api/contacts/terms?api-version=1.0`, userCredentials);
    }

    ActivateRole(params) {
        return this.ContactsClient.post(`/api/contacts/activateRole?ibs=${params.ibsCode}&api-version=1.0`, params.userCredentials);
    }
}