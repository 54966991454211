<template>
  <div>
    <div class="mt-4 mx-auto dataset-page-title" style="width: 1200px">
      <span class="blod">{{
        $translate("DatasetApp.MyIndicator.AddMyIndicators.Title")
      }}</span>
    </div>
    <p class="mt-2 mx-auto dataset-page-sub-title" style="width: 1200px">
      {{ $translate("DatasetApp.MyIndicator.AddMyIndicators.SubTitle") }}
    </p>
    <v-card class="mt-4 mx-auto pa-4" width="1200">
      <v-form ref="form" v-model="valid">
        <div class="mb-1 form-title">
          {{ $translate("DatasetApp.MyIndicator.AddMyIndicators.Message") }}
        </div>
        <v-row no-gutters>
          <v-col cols="8">
            <v-row>
              <v-col cols="4">
                  <v-select
                    v-model="selectedYears"
                    :items="years"
                    :label="
                      $translate(
                        'DatasetApp.MyIndicator.AddMyIndicators.FiscalYear'
                      )
                    "
                    :rules="[rules.required]"
                    outlined
                    dense
                    multiple
                  ></v-select>
              </v-col>
              <v-col cols="4">
                  <v-select
                    v-model="selectedMonth"
                    :items="months"
                    :label="
                      $translate(
                        'DatasetApp.MyIndicator.AddMyIndicators.FirstMonth'
                      )
                    "
                    :rules="[rules.required]"
                    outlined
                    dense
                    item-value="index"
                  ></v-select>
              </v-col>
              <v-col cols="4">
                <v-btn
                  outlined
                  class="text-none"
                  :disabled="!valid"
                  color="primary"
                  @click="generateData"
                  >{{
                    $translate(
                      "DatasetApp.MyIndicator.AddMyIndicators.Generate"
                    )
                  }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-data-table
            style="width: 100vw"
            height="400"
            v-if="items.length > 0"
            calculate-widths
            :headers="headers"
            :items="items"
            :single-select="true"
            item-key="periodId"
            :sort-desc="false"
            class="my-indicators-data-table-class"
            :hide-default-footer="true"
            disable-pagination
          >
            <template v-slot:body="{ items, headers }">
              <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td v-for="(header, key) in headers" :key="key">
                    <v-edit-dialog
                      v-click-outside="save"
                      v-if="key != 0"
                      :return-value="item[header.value]"
                      @save="save"
                      @cancel="cancel"
                      @open="open"
                      @close="close"
                      :save-text="$translate('DatasetApp.Common.Save')"
                      :cancel-text="$translate('DatasetApp.Common.Discard')"
                    >
                      {{ item[header.value] }}
                      <template v-slot:input>
                        <v-text-field
                          type="number"
                          :value="item[header.value]"
                          @input="
                            ($value) =>
                              (item[header.value] = parseFloat($value) || null)
                          "
                          clearable
                          :label="$translate('DatasetApp.Common.Edit')"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <span v-else>{{ item[header.value] }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-row>
        <v-row class="mt-6" no-gutters>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none gradien-btn"
            :disabled="items.length == 0"
            :loading="isLoading"
            dark
            @click="addIndicators()"
          >
            {{ $translate("DatasetApp.MyIndicator.AddMyIndicators.Button") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import IndicatorsService from "@services/indicators-service.js";
import AccountsService from "@services/account-service.js";
import { localizationMixin } from "../../mixins/localization";
import { utilsMixin } from "../../mixins/utils";
import { validationRulesMixin } from "../../mixins/validationRules";
export default {
  mixins: [localizationMixin, utilsMixin, validationRulesMixin],
  props: {
    dialog: {
      type: Boolean,
    },
    account: {
      type: Object,
    },
  },
  data() {
    return {
      selectedYears: [],
      selectedMonth: null,
      indicatorsService: null,
      accountService: null,
      items: [],
      isLoading: false,
      valid: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.Period"
          ),
          value: "PeriodId",
          sortable: false,
        },
        {
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.ChiffreAffaire"
          ),
          value: "ChiffreAffaire",
          sortable: false,
        },
        {
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.TresoNette"
          ),
          value: "TresoNette",
          sortable: false,
        },
        {
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.MasseSalarial"
          ),
          value: "MasseSalarial",
          sortable: false,
        },
        {
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.Remuneration"
          ),
          value: "Remuneration",
          sortable: false,
        },
        /*{
          text: this.$translate(
            "DatasetApp.MyIndicator.DatatableHeaders.ChargesSociales"
          ),
          value: "chargesSociales",
          sortable: false,
        },*/
      ];
    },
  },
  created() {
    this.indicatorsService = new IndicatorsService();
    this.accountService = new AccountsService();
  },
  async mounted() {
    await this.getIndicatorsByAccount();
  },
  methods: {
    save() {},
    cancel() {},
    open() {},
    close() {},
    async getIndicatorsByAccount() {
      this.indicatorsService
        .GetIndicatorsByAccount(this.account.Id)
        .then((response) => {
          this.items = response.data.values;
          if (this.items.length > 0) {
            this.selectedMonth = [
              ...new Set(this.items.map((o) => parseInt(o.FirstFiscalMonth))),
            ][0];
            this.selectedYears = [
              ...new Set(this.items.map((o) => parseInt(o.FiscalYear))),
            ];
          }
        });
    },
    inputed(e, item) {
      console.log("event", e);
      console.log("item", item);
    },
    generateData() {
      this.items = [];
      console.log(this.selectedYears)
      let min = Math.min(...this.selectedYears);
      let max = Math.max(...this.selectedYears);
      console.log(min, max)
      let interval = 1;
      let length = (max - min) / interval + 1;
      this.selectedYears = Array.from({ length }, (_, i) => min + i * interval);
      this.selectedYears.forEach((element) => {
        let currentItems = [];
        let month = this.selectedMonth;
        let monthIndex = 0;
        while (monthIndex < 12) {
          let currentMonth = month > 12 ? month - 12 : month;
          let currentYear = month > 12 ? element + 1 : element;
          
          if(this.selectedMonth > 6)
            currentYear = currentYear - 1;

          let dateYear = new Date().getFullYear();
          let dateMonth = new Date().getMonth() + 1;
          
          if ((currentYear < dateYear || (currentYear == dateYear &&  currentMonth < dateMonth))) {
            currentItems.push({
              PeriodId: `${currentYear}${
                currentMonth.toString().length > 1
                  ? currentMonth
                  : "0" + currentMonth
              }`,
              FiscalYear: element.toString(),
              FirstFiscalMonth:
                this.selectedMonth.toString().length > 1
                  ? this.selectedMonth.toString()
                  : "0" + this.selectedMonth,
              ChiffreAffaire: this.randomIntFromInterval(1000, 50000),
              TresoNette: this.randomIntFromInterval(1000, 50000),
              MasseSalarial: this.randomIntFromInterval(1000, 50000),
              Remuneration: this.randomIntFromInterval(1000, 50000),
              //chargesSociales: this.randomIntFromInterval(1000, 50000),
              AccountId: this.account.Id,
              SessionId: this.account.SessionId,
              AccountNumber: this.account.Ibs,
            });
          }
          else {
            currentItems.push({
              PeriodId: `${currentYear}${
                currentMonth.toString().length > 1
                  ? currentMonth
                  : "0" + currentMonth
              }`,
              FiscalYear: element.toString(),
              FirstFiscalMonth:
                this.selectedMonth.toString().length > 1
                  ? this.selectedMonth.toString()
                  : "0" + this.selectedMonth,
              ChiffreAffaire: null,
              TresoNette: null,
              MasseSalarial: null,
              Remuneration: null,
              //chargesSociales: this.randomIntFromInterval(1000, 50000),
              AccountId: this.account.Id,
              SessionId: this.account.SessionId,
              AccountNumber: this.account.Ibs,
            });
          }
          monthIndex++;
          month++;
        }
        this.items = this.items.concat(currentItems).sort((a, b) => a.PeriodId - b.PeriodId).reverse();
      });
    },
    async addIndicators() {
      this.isLoading = true;
      await this.indicatorsService.CreateIndicator(this.items);
      await this.accountService.UpdateAccount(this.account.Id,{
      })
      this.isLoading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
