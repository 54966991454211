<template>
  <div>
    <div class="mt-4 mx-auto dataset-page-title" style="width: 1200px">
      <span class="blod">{{ $translate("DatasetApp.Bank.Title") }}</span>
    </div>
    <p class="mt-2 mx-auto dataset-page-sub-title" style="width: 1200px">
      {{ $translate("DatasetApp.Bank.SubTitle") }}.
    </p>
    <v-card class="mt-4 mx-auto pa-4" width="1200">
      <v-form ref="form" v-model="valid">
        <div class="mb-1 form-title">
          {{ $translate("DatasetApp.Bank.Message") }}
        </div>
        <v-row>
          <v-col cols="6">
              <v-autocomplete v-model="selectedTypology" :loading="loadingTypologies" :items="typologies" :rules="[rules.required]"
                :search-input.sync="search" no-filter :label="$translate('DatasetApp.Bank.Typology')" outlined
                hide-no-data color="primary" item-value="id" item-text="label"
                dense>
                <template slot="selection" slot-scope="data">
                  {{ data.item.label }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.label }}
                </template>
              </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pt-2" no-gutters>
          <v-spacer></v-spacer>
          <v-btn dark class="text-none gradien-btn" @click="confirm" :loading="isLoading"
            :disabled="!valid || isLoading">
            {{ $translate("DatasetApp.Bank.Button") }}
          </v-btn>
        </v-row>
        </v-form>
    </v-card>
    <v-dialog v-model="dialogchangetypology" max-width="380px">
      <ChangeTypologyConfirmationDialog v-if="dialogchangetypology" :dialog="dialogchangetypology" 
        @closeDialog="dialogchangetypology = false;"
        @confirm="addBankData" />
    </v-dialog>
  </div>
</template>
<script>
import AccountsService from "@services/account-service.js";
import ChangeTypologyConfirmationDialog from "./ChangeTypologyConfirmationDialog.vue";
import BankingDataService from "@services/bankingdata-service.js";
import { localizationMixin } from "../../mixins/localization";
import { validationRulesMixin } from "../../mixins/validationRules";

export default {
  components:{
    ChangeTypologyConfirmationDialog
  },
  mixins: [localizationMixin, validationRulesMixin],
  props: {
    dialog: {
      type: Boolean,
    },
    account: {
      type: Object,
    },
  },
  data() {
    return {
      accountService: null,
      bankingDataService: null,
      typologies: [],
      selectedTypology: null,
      isLoading: false,
      loadingTypologies: false,
      search: '',
      valid: true,
      dialogchangetypology:false
    };
  },
  watch: {
    search(val) {
      console.table(val)
      if (val && val.length >= 3) this.querySelections(val);
    },
  },
  created() {
    this.accountService = new AccountsService();
    this.bankingDataService = new BankingDataService();
    this.accountService.GetTypologies().then((response) => {
      this.typologies = JSON.parse(response.data);
      this.selectedTypology = this.account.SynchronizedBankTypologyId ? this.account.SynchronizedBankTypologyId : null
    });
  },
  methods: {
    querySelections(v) {
      this.loadingAccounts = true;

      this.accountService.SearchAccountFromReferentiel(v).then((response) => {
        this.accounts = JSON.parse(response.data);
        this.loadingAccounts = false;
      });
    },
    async confirm() {
      if(this.account.SynchronizedBankTypologyId != null)
        this.dialogchangetypology = true
      else
        {
          await this.addBankData()
        }
    },
    async addBankData() {
      this.isLoading = true;

      await this.accountService.AddBankinData({
        accountNumber: this.account.Ibs,
        typologyId: this.selectedTypology,
      });

      this.isLoading = false;
      this.$emit("closeDialog");
    }
  },
};
</script>
  