<template>
  <v-form ref="form" v-model="valid">
    <v-card class="add-session-dialog-class">
      <v-card-title class="mb-2 pr-2">
        <span class="primary--text">{{
            $translate("DatasetApp.Session.AddSession.Title")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn dark icon color="primary" @click="$emit('closeDialog')">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mx-12" no-gutters>
            <v-col cols="12">
              <v-text-field v-model="session.title" :label="
                $translate('DatasetApp.Session.AddSession.SessionTitle')
              " :rules="[rules.required]" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="session.description" label="Description" dense outlined></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-none" outlined dark color="primary" @click="$emit('closeDialog')">
          {{ $translate("DatasetApp.Common.Discard") }}
        </v-btn>
        <v-btn dark class="text-none gradien-btn" @click="addSession" :loading="isLoading"
          :disabled="!valid || isLoading">
          {{ $translate("DatasetApp.Common.Validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import SessionsService from "@services/sessions-service.js";
import { localizationMixin } from "../../mixins/localization";
import { validationRulesMixin } from "../../mixins/validationRules";

export default {
  mixins: [localizationMixin, validationRulesMixin],
  props: {
    dialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      sessionsService: null,
      isLoading: false,
      session: {},
      valid: true
    };
  },
  created() {
    this.sessionsService = new SessionsService();
  },
  methods: {
    async addSession() {
      this.isLoading = true;
      await this.sessionsService.CreateSession(this.session);
      this.isLoading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang="scss">
.add-session-dialog-class {
  .theme--light.v-text-field--outlined {
    border-radius: 8px;
  }

  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    border: 2px solid #e9edfc !important;
  }

  .theme--light.theme--light.v-label:is(.v-label--active) {
    color: #1C00C0;
  }

  .v-btn {
    border-radius: 8px;
  }

  .v-btn[disabled] {
    background: linear-gradient(78.94deg, #c3cbd7 -10.04%, #c3cbd7 118.67%);
  }

  .gradien-btn {
    background: linear-gradient(78.94deg, #aa21cf -10.04%, #1C00C0 118.67%);
  }
}
</style>