<template>
    <svg :width="width" :height="width" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.1253 1.12868C19.6879 0.566072 20.451 0.25 21.2466 0.25C22.0423 0.25 22.8054 0.566072 23.368 1.12868C23.9306 1.69129 24.2467 2.45436 24.2467 3.25001C24.2467 4.04567 23.9306 4.80873 23.368 5.37134L22.2636 6.47575C22.2571 6.48276 22.2504 6.48969 22.2436 6.49651C22.2368 6.50333 22.2298 6.51 22.2228 6.5165L12.102 16.6373C12.0149 16.7244 11.9078 16.7886 11.7901 16.8245L7.21552 18.2173C6.95052 18.298 6.66263 18.226 6.46675 18.0301C6.27088 17.8343 6.19891 17.5464 6.27958 17.2814L7.67215 12.7066C7.708 12.5888 7.77228 12.4817 7.85932 12.3947L19.1253 1.12868ZM21.7129 4.90513L22.3073 4.31068C22.5886 4.02938 22.7467 3.64784 22.7467 3.25001C22.7467 2.85219 22.5886 2.47065 22.3073 2.18934C22.026 1.90804 21.6445 1.75 21.2466 1.75C20.8488 1.75 20.4673 1.90804 20.186 2.18934L19.5915 2.7838L21.7129 4.90513ZM18.5309 3.84446L20.6522 5.9658L11.5716 15.0464L9.45031 12.925L18.5309 3.84446ZM8.74315 14.3392L8.12416 16.3727L10.1575 15.7536L8.74315 14.3392ZM1.12868 19.1287C1.69129 18.5661 2.45435 18.25 3.25 18.25H3.997C4.41121 18.25 4.747 18.5858 4.747 19C4.747 19.4142 4.41121 19.75 3.997 19.75H3.25C2.85218 19.75 2.47064 19.908 2.18934 20.1893C1.90804 20.4706 1.75 20.8522 1.75 21.25C1.75 21.6478 1.90804 22.0294 2.18934 22.3107C2.47064 22.592 2.85218 22.75 3.25 22.75H19C19.4142 22.75 19.75 23.0858 19.75 23.5C19.75 23.9142 19.4142 24.25 19 24.25H3.25C2.45435 24.25 1.69129 23.9339 1.12868 23.3713C0.566071 22.8087 0.25 22.0456 0.25 21.25C0.25 20.4544 0.566071 19.6913 1.12868 19.1287Z"
            :fill="color" />
    </svg>
</template>

<script>
export default {
    name: 'Pen',
    props: {
        color: {
            type: String,
            default: 'white',
            required: false,
        },
        width: {
            type: Number,
            default: 50,
            required: false,
        },
    },
}
</script>

<style>

</style>