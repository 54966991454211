<template>
    <svg :width="width" :height="width" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.625 4.2625V3.3C20.625 2.23685 19.8415 1.375 18.875 1.375H3.125C2.1585 1.375 1.375 2.23685 1.375 3.3C1.375 3.6025 1.375 4.2625 1.375 4.2625M20.625 4.2625C20.625 4.2625 19.751 4.31448 19.75 4.2625C19.749 4.21053 19.75 18.6985 19.75 18.7C19.75 18.7001 19.75 18.7 19.75 18.7001C19.75 19.7632 18.9665 20.625 18 20.625H4C3.0335 20.625 2.25 19.7632 2.25 18.7C2.25 13.6616 2.25 8.69926 2.25 4.2625C2.02159 4.2625 1.375 4.2625 1.375 4.2625M20.625 4.2625H1.375M14.85 9.075H7.15"
            :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>

<script>
export default {
    name: 'Archive',
    props: {
        color: {
            type: String,
            default: 'white',
            required: false,
        },
        width: {
            type: Number,
            default: 50,
            required: false,
        },
    },
}
</script>

<style>

</style>