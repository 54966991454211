const lightTheme = {
  primary: '#1C00C0',
  secondary: "#AC22D0",
  accent: "#483698",
  error: "#BC204B",
  success: "#D0EBCA",
  pending: "#E9EDFC"
};
export const baseProps = {
  props: {
    token: {
      type: String,
      default: () => sessionStorage.getItem("adal.idtoken"),
    },
    language: {
      type: String,
      default: "fr",
    },
  },
  mounted() {
    this.$vuetify.theme.themes.light = lightTheme;
  }
};
