<template>
  <div>
    <v-row class="my-1" align="center" no-gutters>
      <v-btn
        dark
        class="v-btn-rec"
        color="primary"
        @click="$emit('closeDetails')"
      >
      <ArrowUpLeftTopIcon color="white" :width="20" />
      </v-btn>
      <div class="dataset-page-title ml-4">
        <span class="blod">{{ session.Title }} </span
        ><span class="blod" v-if="account">|</span>
        {{ account ? account.Name : "" }}
      </div>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-tabs class="dataset-tabs" v-model="tab">
        <v-tab
          class="text-none font-weight-bold"
          v-for="(item, index) in items"
          :key="index"
          :disabled="item.disabled()"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </v-row>

    <AddAccountDialog
      v-if="tab == 0"
      :selectedAccount="account"
      :sessionId="session.Id"
      @closeDialog="$emit('closeDetails')"
    />
    <AddBankDataDialog
      v-if="tab == 1"
      :account="account"
      @closeDialog="$emit('closeDetails')"
    />
    <AddMyIndicatorsDialog
      v-if="tab == 2"
      :account="account"
      @closeDialog="$emit('closeDetails')"
    />
  </div>
</template>

<script>
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import { utilsMixin } from "@mixins/utils";
import AddMyIndicatorsDialog from "./AddMyIndicatorsDialog.vue";
import AddAccountDialog from "./AddAccountDialog.vue";
import AddBankDataDialog from "./AddBankDataDialog.vue";

//Icons
import ArrowUpLeftTopIcon from "@assets/ArrowUpLeftTop.vue"

export default {
  components: {
    AddMyIndicatorsDialog,
    AddAccountDialog,
    AddBankDataDialog,
    //Icons
    ArrowUpLeftTopIcon
  },
  mixins: [baseProps, localizationMixin, utilsMixin],
  props: {
    account: {
      type: Object,
    },
    session: {
      type: Object,
    },
    tabIndex: {
      type: Number,
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    items() {
      return [
        {
          title: this.$translate("DatasetApp.Account.Account"),
          disabled: () => {
            return false;
          },
        },
        {
          title: this.$translate("DatasetApp.Bank.BankData"),
          disabled: () => {
            return this.account == null || this.account.SynchronizedBank == 1
              ? true
              : false;
          },
        },
        {
          title: this.$translate("DatasetApp.MyIndicator.Indicators"),
          disabled: () => {
            return this.account == null || this.account.SynchronizedBank == 1 || this.account.SynchronizedBankTypologyId == null ||
              this.account.SynchronizedIndicators == 1
              ? true
              : false;
          },
        },
      ];
    },
  },
  created() {
    this.tab = this.tabIndex;
  },
};
</script>