import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle Indicators
 */
export default class IndicatorsService extends AbstractService {

    constructor() {
        super();
        this.IndicatorsClient = super.createClient(configuration.DatasetApi.BaseUrl);
    }

    /**
     * Get Indicators with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetIndicators(search, itemsPerPage, pageNumber, sortBy, sortDesc) {
        var params = {
            "$top": itemsPerPage,
            "$skip": itemsPerPage * (pageNumber - 1),
            "$orderBy": getOrderby(sortBy, sortDesc),
            "$count": true,
        }
        
        let filters = []
        if(search != null && search != '')
            filters.push(`contains(title,'${search}')`)
        
        if(filters.length > 0)
            params["$filter"] = filters.join(" and ")
        
        return this.IndicatorsClient.get("v2/Indicator", {
            params : params
        });

    }

    /**
    * Get Indicators By Account
    * @param {string} accountId
    */
    GetIndicatorsByAccount(accountId) {
        let IndicatorConfig = {
            params: {
                "$count": true
            }
        };

        IndicatorConfig.params["$filter"] = `AccountId eq ${accountId}`

        return this.IndicatorsClient.get("v2/Indicator", IndicatorConfig);
    }

    /**
    * Get Indicators by Request
    * @param {string} requestId
    */
    GetIndicatorsByRequest(requestId) {
        let IndicatorConfig = {
            params: {
                "$count": true,
            }
        };

        if (requestId && requestId !== "") {
            IndicatorConfig.params["$filter"] = `RequestId eq ${requestId}`
        }

        return this.IndicatorsClient.get("v2/Indicator", IndicatorConfig);
    }

    /**
     * Delete Indicator by id
     * @param {string} id
     */
    DeleteIndicator(id) {
        return this.IndicatorsClient.delete(`v2/Indicator(${id})`)
    }

    /**
     * Updates Indicator by id
     * @param {string} id
     * @param {any} IndicatorBody
     */
    UpdateIndicator(id, IndicatorBody) {
        return this.IndicatorsClient.patch(`v2/Indicator(${id})`, IndicatorBody)
    }

    /**
     * Create a Indicator
     * @param {any} IndicatorBody
     */
    CreateIndicator(IndicatorBody) {
        return this.IndicatorsClient.post("v2/Indicator/CreateIndicators", IndicatorBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy && sortBy[0]) {
        orderby = sortBy[0];
        if (sortDesc && sortDesc[0])
            orderby = orderby + " desc"
    }

    return orderby;
}